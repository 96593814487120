import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/contexts";

function RequiredAuth({ children }) {
  // get user
  const { auth } = useGlobalContext();
  if (!auth?._id) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default RequiredAuth;
